@use '@angular/material' as mat;

$mat-codex-purple: (
    50: #ececf5,
    100: #d0d0e5,
    200: #b1b1d4,
    300: #9292c2,
    400: #7a7ab5,
    500: #8000ff,
    600: #8000ff,
    700: #515197,
    800: #47478d,
    900: #35357d,
    A100: #cacaff,
    A200: #9797ff,
    A400: #6464ff,
    A700: #4b4bff,
    contrast: (
        50: #000000,
        100: #000000,
        200: #000000,
        300: #000000,
        400: #000000,
        500: #ffffff,
        600: #ffffff,
        700: #ffffff,
        800: #ffffff,
        900: #ffffff,
        A100: #000000,
        A200: #000000,
        A400: #ffffff,
        A700: #ffffff,
    ),
);
$primary: mat.m2-define-palette($mat-codex-purple, 500);
$accent: mat.m2-define-palette(mat.$m2-indigo-palette, 500);
$warn: mat.m2-define-palette(mat.$m2-red-palette, 50);

$my-theme: mat.m2-define-light-theme(
    (
        color: (
            primary: $primary,
            accent: $accent,
            warn: $warn,
        ),
        typography: mat.m2-define-typography-config(
                $font-family: 'Poppins',
            ),
        density: 0,
    )
);

@include mat.all-component-themes($my-theme);

/* You can add global styles to this file, and also import other style files */
body {
    margin: 0;
    font-family: Poppins;
    height: 100vh;
}

* {
    box-sizing: border-box;
}

.mat-form-field-wrapper,
mat-form-field {
    width: 100%;
}

/** Popper **/
body .float-ui-container > .float-ui-arrow {
    border-color: #9c38ff;
}

body .float-ui-container[data-float-ui-placement='top'].errorPopper > .float-ui-arrow {
    border-top-color: #ff003d;
}

body .float-ui-container[data-float-ui-placement='right'].errorPopper > .float-ui-arrow {
    border-right-color: #ff003d;
}

body .float-ui-container[data-float-ui-placement='left'].errorPopper > .float-ui-arrow {
    border-left-color: #ff003d;
}

body .float-ui-container[data-float-ui-placement='bottom'].errorPopper > .float-ui-arrow {
    border-bottom-color: #ff003d;
}

body .float-ui-container.errorPopper {
    border: 1px solid #ff003d !important;
    color: #ff003d;
}

body .float-ui-container ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
}

body .float-ui-container {
    z-index: 10000;
    max-width: 800px;
    border: 1px solid #9c38ff !important;
    border-radius: 4px !important;
    background-color: #fff;
    box-shadow:
        0px 2px 4px -1px rgb(0 0 0 / 20%),
        0px 4px 5px 0px rgb(0 0 0 / 14%),
        0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.cdk-overlay-container {
    z-index: 100000 !important;
}
